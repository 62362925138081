"use client";

import { LEAD_FORM_LOCAL_STORAGE_KEY } from "@shared/constants";

import { throwUndefinedVariable } from "@shared/functions/throwError";

class LocalStorage {
  private static instance?: LocalStorage;

  key: string;

  useLocalStorage: boolean | undefined;

  /**
   * Initialize LocalStorage
   *
   * @param {string} key Name of Local Storage container
   * @throws `Error` if `key` is undefined
   */
  private constructor(key: string) {
    if (!key) {
      throwUndefinedVariable("LEAD_FORM_LOCAL_STORAGE_KEY");
    }

    this.key = key;

    try {
      if (typeof window !== undefined) {
        this.useLocalStorage = typeof window.localStorage !== undefined;
      }
    } catch (e) {
      this.useLocalStorage = false;
    }
  }

  /**
   * Get or create the singleton instance of LocalStorage.
   *
   * @param {string} key Name of Local Storage container
   * @returns A single instance of this class
   */
  static getInstance(key: string): LocalStorage {
    if (!this.instance) {
      this.instance = new LocalStorage(key);
    }

    return this.instance;
  }

  /**
   * Create new Local Storage item
   *
   * @param {string|object} value Value to add to Local Storage
   *
   * @returns {mixed} `false` if Local Storage is not enabled.
   *                  `true` if item is saved.
   *                  `QuotaExceededError` DOMException exception if the new value couldn't be set.
   */
  create(value: string | Record<string, string | number>) {
    if (!this.useLocalStorage) {
      return false;
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    window.localStorage.setItem(this.key, value as string);

    return true;
  }

  /**
   * Get value of the given Local Storage item
   *
   * @param {boolean} parse Whether to convert the value into a JSON object (default to true)
   *
   * @returns {mixed} The current value associated with the given key, or null if the given key does not exist.
   */
  read(parse: boolean = true) {
    if (!this.useLocalStorage) {
      return false;
    }

    const value = window.localStorage.getItem(this.key);

    if (!value || !parse) {
      return value;
    }

    return JSON.parse(value || "");
  }

  /**
   * Update an existing Local Storage item
   *
   * @param {boolean} parse Whether to convert the given value into a JSON object
   * @param {string|object} value Value to add to Local Storage
   *
   * @returns {mixed} `false` if Local Storage is not enabled.
   *                  `true` if item is saved.
   *                  `QuotaExceededError` DOMException exception if the new value couldn't be set.
   */
  update(value: Record<string, string | number>) {
    if (!this.useLocalStorage) {
      return false;
    }

    const isObject = typeof value === "object";
    let item = this.read(isObject);

    if (item !== null) {
      if (isObject) {
        value = { ...item, ...value };
      } else {
        item += value;
      }
    }

    return this.create(value);
  }

  /**
   * Delete an existing Local Storage item
   *
   * @returns {boolean} `true` if Local Storage item was deleted; `false` otherwise.
   */
  delete() {
    if (!this.useLocalStorage) {
      return false;
    }

    window.localStorage.removeItem(this.key);

    return window.localStorage.getItem(this.key) === null;
  }
}

const localStorage = LocalStorage.getInstance(LEAD_FORM_LOCAL_STORAGE_KEY);
export default localStorage;
