import getLaunchDarklyVariation from "@server/launch-darkly/getLaunchDarklyVariation";

import type LaunchDarkly from "@packages/types/launch-darkly";

export default async function getSecondaryOfferFlowEnabled(
  additionalContext?: LaunchDarkly.AdditionalContext,
) {
  return await getLaunchDarklyVariation<boolean>({
    additionalContext,
    defaultValue: false,
    domainPrefix: false,
    flagName: "fiona-secondary-offers",
  });
}
